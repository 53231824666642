/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    MdAdd,
    MdDelete,
    MdSettings,
    MdContentCopy,
    MdClose,
} from 'react-icons/md';
import { useSelector } from 'react-redux';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import {
    IconButton,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import AppConfigRobotType from '~/components/AppConfigRobotType';
import CustomDialogTitle from '~/components/CustomDialogTitle';
import DataTable from '~/components/DataTable';
import FormCheckList from '~/components/Form/CheckList';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import getDateLocale from '~/util/GetDateLocale';

export default function Triggers({
    setToast,
    handleError,
    appSettings,
    robotTypes,
    currType,
    setCurrType,
}) {
    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);

    const { id, group, application_content } = appSettings;
    const { dialog_id } = application_content;
    const group_id = group ? group.id : '*';

    const defaultObj = {
        application_id: id,
        dialog_id,
    };

    const app_settings = appSettings.settings || {};
    const map_navigation = app_settings.map_navigation || {};

    const [isLoading, setIsLoading] = useState(true);

    const [requiresConfig, setRequiresConfig] = useState(false);

    const [pluginconfigList, setPluginconfigList] = useState([]);
    const [plugintypeList, setPlugintypeList] = useState([]);
    const [triggerList, setTriggerList] = useState([]);
    const [actionList, setActionList] = useState([]);
    const [points, setPoints] = useState([]);

    const [currItem, setCurrItem] = useState(null);
    const [triggerObj, setTriggerObj] = useState(defaultObj);
    const [operation, setOperation] = useState('create');

    const [showOpen, setShowOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [formOpen, setFormOpen] = useState(false);

    const r_key = currType.slug ? currType.slug : 'all';
    const r_type_navigation = map_navigation[r_key] || {};
    // const r_type_settings = autonomousConfig[r_key] || {};

    function requestError(error) {
        handleError(error);
        setIsLoading(false);
    }

    async function loadPluginconfigs(action_id) {
        await api
            .get(`pluginconfigs?action_id=${action_id}`)
            .then(response => {
                const filtered = response.data.filter(c => {
                    return c.group_id === group_id;
                });
                setPluginconfigList(filtered);
            })
            .catch(error => requestError(error));
    }

    async function onActionChanged(val) {
        const action = actionList.find(a => {
            return a.id === val;
        });
        const require_config = action ? action.require_config : false;

        setRequiresConfig(require_config || false);
        if (require_config) {
            await loadPluginconfigs(val);
        } else {
            setPluginconfigList([]);
        }
    }

    async function loadTrigger(_id) {
        if (!_id) {
            return;
        }
        setIsLoading(true);
        await api
            .get(`triggers/${_id}`)
            .then(response => {
                const { data } = response;
                if (data.action_id) {
                    onActionChanged(data.action_id);
                }
                setTriggerObj(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadTriggers() {
        if (!dialog_id) {
            setToast({
                color: 'error',
                body: (
                    <LocaleMessage msg="page.applications.conversation.triggers.error.application_content" />
                ),
            });
            return;
        }
        await api
            .get(`triggers?dialog_id=${dialog_id}`)
            .then(response => {
                const filtered = response.data.filter(t => {
                    return t.group_id === group_id;
                });

                const data = filtered.map(t => {
                    const updated = new Date(t.updated);
                    return {
                        ...t,
                        label: <LocaleMessage msg={t.action} />,
                        pluginconfig: t.pluginconfig || '---',
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });

                setTriggerList(data);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function loadActions() {
        try {
            const response = await api.get(
                `pluginspaces/actions?allow_trigger=true`
            );

            const { data } = response;
            setActionList(data);
            return;
        } catch (err) {
            requestError(err);
        }
    }

    async function loadPlugintypes() {
        await api
            .get(`pluginspaces/plugins`)
            .then(response => {
                const { data } = response;
                const available = data.filter(plugin => {
                    const p_settings = plugin.settings;
                    const availability = p_settings.availability || {};
                    return availability.conversation_apps;
                });
                setPlugintypeList(available);
            })
            .catch(error => requestError(error));
    }

    async function loadMapPoints(_id) {
        if (_id) {
            setIsLoading(true);
            await api
                .get(`map_points?map_layer=${_id}`)
                .then(response => {
                    const data = response.data.map(z => ({
                        id: z.id,
                        name: z.name,
                        reference: z.reference,
                    }));
                    setPoints(data);
                })
                .catch(error => handleError(error));
        } else {
            setPoints([]);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadPlugintypes();
        loadActions();
    }, []);

    useEffect(() => {
        loadTriggers();
    }, [date_loc]);

    useEffect(() => {
        loadMapPoints(r_type_navigation.map_layer_id);
    }, [r_type_navigation.map_layer_id]);

    async function deleteItem() {
        setIsLoading(true);
        setDeleteOpen(false);

        await api
            .delete(`triggers/${currItem}`)
            .then(async () => {
                await loadTriggers();
                setToast({
                    color: 'success',
                    body: (
                        <LocaleMessage msg="page.applications.conversation.triggers.deleted" />
                    ),
                });
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function handlePlugintypeChange(event) {
        const val = event.target.value;
        setTriggerObj({ ...defaultObj, plugintype_id: val });
    }

    function handleActionChange(v) {
        onActionChanged(v);
        setTriggerObj({ ...triggerObj, action_id: v, pluginconfig_id: null });
    }

    function handleFormClose(event) {
        event.preventDefault();
        setFormOpen(false);
    }

    function handleDeleteClose(event) {
        event.preventDefault();
        setDeleteOpen(false);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setShowOpen(false);
    }

    function handleDeleteItem(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDeleteOpen(true);
    }

    function handleTriggerSelection(event, _id) {
        loadTrigger(_id);
        setCurrItem(_id);
        setShowOpen(true);
    }

    async function handleTriggerSettings(event, _id) {
        event.stopPropagation();
        setCurrItem(_id);
        setOperation(_id === 'new' ? 'create' : 'update');
        if (_id !== 'new') {
            loadTrigger(_id);
        } else {
            setTriggerObj(defaultObj);
        }
        setFormOpen(true);
    }

    async function handleTriggerObjSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        setFormOpen(false);

        const data = { ...triggerObj };

        if (operation === 'create') {
            await api
                .post(`triggers`, data)
                .then(async () => {
                    setTriggerObj(defaultObj);
                    setToast({
                        color: 'success',
                        body: (
                            <LocaleMessage msg="page.applications.conversation.triggers.created" />
                        ),
                    });
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`triggers/${currItem}`, data)
                .then(async () => {
                    setTriggerObj(defaultObj);
                    setToast({
                        color: 'success',
                        body: (
                            <LocaleMessage msg="page.applications.conversation.triggers.updated" />
                        ),
                    });
                })
                .catch(error => requestError(error));
        }
        await loadTriggers();
        setIsLoading(false);
    }

    function handleCopy() {
        setToast({
            color: 'info',
            body: (
                <LocaleMessage msg="page.applications.conversation.triggers.message.copied" />
            ),
        });
    }

    function renderTriggerList() {
        const headCells = [
            {
                id: 'label',
                label: <LocaleMessage msg="table.headers.action" />,
            },
            {
                id: 'command',
                label: <LocaleMessage msg="table.headers.command" />,
            },
            {
                id: 'pluginconfig',
                label: <LocaleMessage msg="table.headers.pluginconfig" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'settings',
                label: <LocaleMessage msg="button.settings" />,
                icon: <MdSettings />,
                action: handleTriggerSettings,
            },
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteItem,
            },
        ];

        return (
            <div className="mt-3" style={{ minHeight: '150px', width: '100%' }}>
                <DataTable
                    data={triggerList}
                    orderColumn="action"
                    headerColumns={headCells}
                    rowActions={rowActions}
                    handleTableRowClick={(event, _id) =>
                        handleTriggerSelection(event, _id)
                    }
                />
            </div>
        );
    }

    function renderFormDialog() {
        const filter = triggerObj.plugintype_id || '';
        const filteredAction = actionList.filter(a => {
            return a.plugintype_id === filter;
        });

        const trigger_limitations =
            triggerObj.settings && triggerObj.settings.limitations
                ? triggerObj.settings.limitations
                : {};
        const r_type_limitations =
            trigger_limitations && trigger_limitations[r_key]
                ? trigger_limitations[r_key]
                : {};

        return (
            <Dialog
                open={formOpen}
                onClose={handleFormClose}
                maxWidth="sm"
                fullWidth
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '0px 15px 0px 0px',
                    }}
                >
                    <CustomDialogTitle
                        title={
                            <LocaleMessage
                                msg={`page.applications.conversation.triggers.${
                                    operation === 'create' ? 'create' : 'update'
                                }.title`}
                            />
                        }
                    />
                    <IconButton
                        aria-label="close"
                        onClick={handleFormClose}
                        style={{ padding: '5px' }}
                    >
                        <MdClose size={20} />
                    </IconButton>
                </div>
                <DialogContent>
                    <FormControl className="mb-3" fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.applications.conversation.triggers.label.plugintype" />
                        </InputLabel>
                        <Select
                            value={
                                triggerObj && triggerObj.plugintype_id
                                    ? triggerObj.plugintype_id
                                    : ''
                            }
                            onChange={event => handlePlugintypeChange(event)}
                            disabled={operation === 'update'}
                        >
                            {plugintypeList.map(p => (
                                <MenuItem key={p.id} value={p.id}>
                                    {p.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {triggerObj && triggerObj.plugintype_id ? (
                        <FormControl className="mb-3" fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.applications.conversation.triggers.label.action" />
                            </InputLabel>
                            <Select
                                id="trigger-action"
                                value={
                                    triggerObj && triggerObj.action_id
                                        ? triggerObj.action_id
                                        : ''
                                }
                                onChange={event =>
                                    handleActionChange(
                                        event && event.target
                                            ? event.target.value
                                            : ''
                                    )
                                }
                                disabled={operation === 'update'}
                            >
                                {filteredAction.map(a => (
                                    <MenuItem key={a.id} value={a.id}>
                                        <LocaleMessage msg={a.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : null}
                    {triggerObj && triggerObj.action_id && requiresConfig ? (
                        <FormControl className="mb-3" fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.applications.conversation.triggers.label.pluginconfig" />
                            </InputLabel>
                            <Select
                                id="pluginconfig"
                                value={
                                    triggerObj && triggerObj.pluginconfig_id
                                        ? triggerObj.pluginconfig_id
                                        : ''
                                }
                                onChange={event =>
                                    setTriggerObj({
                                        ...triggerObj,
                                        pluginconfig_id: event.target.value,
                                    })
                                }
                            >
                                {pluginconfigList.map(c => (
                                    <MenuItem key={c.id} value={c.id}>
                                        {c.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : null}
                    <div className="col-12 mb-1 row">
                        <FormSwitch
                            value={triggerObj.limited || false}
                            onChange={event =>
                                setTriggerObj({
                                    ...triggerObj,
                                    limited: event.target.checked,
                                })
                            }
                            label={
                                <LocaleMessage msg="page.applications.conversation.triggers.limit" />
                            }
                        />
                    </div>
                    {triggerObj.limited ? (
                        <AppConfigRobotType
                            className="col-12 mb-3"
                            robotTypes={robotTypes}
                            currType={currType}
                            setCurrType={t => setCurrType(t)}
                        >
                            <>
                                <div className="col-12 mb-3">
                                    <LocaleMessage msg="page.applications.conversation.triggers.limit.point" />
                                    :
                                </div>
                                <FormCheckList
                                    multiple
                                    options={[
                                        {
                                            id: 'unknown',
                                            name: (
                                                <LocaleMessage msg="label.form.point.unknown" />
                                            ),
                                            reference: '',
                                        },
                                        ...points,
                                    ]}
                                    listKey="points"
                                    settings={
                                        r_type_limitations &&
                                        r_type_limitations.location
                                            ? r_type_limitations.location
                                            : {}
                                    }
                                    onChange={list => {
                                        setTriggerObj({
                                            ...triggerObj,
                                            settings: {
                                                ...triggerObj.settings,
                                                limitations: {
                                                    ...trigger_limitations,
                                                    [r_key]: {
                                                        ...r_type_limitations,
                                                        location: list,
                                                    },
                                                },
                                            },
                                        });
                                    }}
                                />
                            </>
                        </AppConfigRobotType>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={event => handleFormClose(event)}
                        color="primary"
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={event => handleTriggerObjSubmit(event)}
                        color="primary"
                    >
                        {operation === 'create' ? (
                            <LocaleMessage msg="button.create" />
                        ) : (
                            <LocaleMessage msg="button.save" />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function renderDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteOpen}
                onClose={handleDeleteClose}
                title={
                    <LocaleMessage msg="page.applications.conversation.triggers.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteItem(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function renderShowDialog() {
        return (
            <div>
                <Dialog
                    open={showOpen}
                    onClose={handleDialogClose}
                    maxWidth="sm"
                    fullWidth
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '0px 15px 0px 0px',
                        }}
                    >
                        <CustomDialogTitle
                            title={
                                <LocaleMessage msg="page.applications.conversation.triggers.use_title" />
                            }
                        />
                        <IconButton
                            aria-label="close"
                            onClick={handleDialogClose}
                            style={{ padding: '5px' }}
                        >
                            <MdClose size={20} />
                        </IconButton>
                    </div>
                    <DialogContent className="mb-5">
                        <DialogContentText>
                            <LocaleMessage msg="page.applications.conversation.triggers.use_content" />
                        </DialogContentText>

                        <Card>
                            <CardContent
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '20px',
                                    // backgroundColor: '#f5f5f5',
                                    fontSize: '14px',
                                }}
                            >
                                <span style={{ margin: '0px' }}>
                                    {triggerObj.command}
                                </span>

                                <CopyToClipboard
                                    text={triggerObj.command}
                                    onCopy={() => handleCopy()}
                                >
                                    <Button
                                        variant="outlined"
                                        startIcon={<MdContentCopy />}
                                    >
                                        <LocaleMessage msg="button.copy" />
                                    </Button>
                                </CopyToClipboard>
                            </CardContent>
                        </Card>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }

    return (
        <div className="col-12 mb-3">
            <div
                className="sidecard-header row"
                style={{
                    justifyContent: 'space-between',
                    padding: '20px 15px 0px 15px',
                }}
            >
                <h2>
                    <LocaleMessage msg="page.applications.conversation.triggers.label.action_list" />
                </h2>
                <div className="col-3" style={{ padding: '0px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={event => handleTriggerSettings(event, 'new')}
                    >
                        <MdAdd
                            size={20}
                            style={{
                                color: '#fff',
                            }}
                        />
                        <LocaleMessage msg="page.applications.conversation.triggers.new" />
                    </Button>
                </div>
            </div>
            <div
                style={{
                    padding: '0px 15px',
                }}
            >
                <br />
                <LocaleMessage msg="page.applications.conversation.triggers.label.tutorial_dialog" />
                <br />
                <LocaleMessage msg="page.applications.conversation.triggers.label.caution_dialog" />
            </div>
            <div className="sidecard-body">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        {deleteOpen ? renderDeleteDialog() : null}
                        {showOpen ? renderShowDialog() : null}
                        {formOpen ? renderFormDialog() : null}

                        {renderTriggerList()}
                    </>
                )}
            </div>
        </div>
    );
}

Triggers.propTypes = {
    setToast: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    appSettings: PropTypes.object.isRequired,
    robotTypes: PropTypes.array.isRequired,
    currType: PropTypes.object.isRequired,
    setCurrType: PropTypes.func.isRequired,
};
