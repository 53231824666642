/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Divider } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import CounterCard from '~/components/Reports/CounterCard';
import ExportCSVButton from '~/components/Reports/CSVButton';
import ReportDateChart from '~/components/Reports/ReportDateChart';
import ReportHeader from '~/components/Reports/ReportHeader';
import ReportPieChart from '~/components/Reports/ReportPieChart';
import ReportTimeChart from '~/components/Reports/ReportTimeChart';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetDateTimeLabel from '~/util/GetDateTimeLabel';
import GetFileName from '~/util/GetFileName';
import GetPeriodDates from '~/util/GetPeriodDates';
import lng_labels from '~/util/LangMessages';

import { TotalContainer, InfoContainer, TableArea } from './style';

const warnings = ['warnings.version.cruzr_v3110', 'warnings.version.temi_v250'];

export default function AppIntegrationsReports({
    settings,
    headerSettings,
    requestError,
    counterData,
    language,
    options,
}) {
    const lng_all = lng_labels[language] || {};

    const {
        fromISO,
        untilISO,
        minDate,
        shortcuts,
        handleFromDateChange,
        handleUntilDateChange,
        handlePeriodShortcut,
    } = headerSettings;

    const { active, colors, format, locale, dateOptions, dark_mode } = settings;

    const mountedRef = React.useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    const [rawLogData, setRawLogData] = useState({});
    const [actionLogList, setActionLogList] = useState([]);
    const [groupedData, setGroupedData] = useState({});
    const [singleObjFilter, setSingleObjFilter] = useState(null);

    const headers_file = [
        {
            key: 'created_date',
            label: 'Date',
        },
        {
            key: 'created_time',
            label: 'Time',
        },
        { key: 'group', label: 'Group' },
        { key: 'appkey', label: 'API Key' },
        { key: 'application', label: 'Application' },
        { key: 'function_label', label: 'Function' },
        { key: 'result_label', label: 'Result' },
    ];

    const headers_table = [
        {
            id: 'created',
            order_by: 'timestamp',
            label: <LocaleMessage msg="table.headers.date" />,
        },
        {
            id: 'appkey',
            label: 'API Key',
        },
        {
            id: 'application',
            label: <LocaleMessage msg="table.headers.application" />,
        },
        {
            id: 'function_label',
            label: <LocaleMessage msg="table.headers.action" />,
        },
        {
            id: 'result_label',
            label: (
                <LocaleMessage msg="page.reports.app_integrations.label.results" />
            ),
        },
    ];

    function filterRawData(data) {
        const { groups, applications } = counterData;
        const { period, logs } = data;

        const a_groups = [];
        const a_appkeys = [];
        const a_function_types = [];
        const a_results = [];
        const a_applications = [];
        const a_date = [];
        const a_time = [];

        const appkeys = {};

        const alist = logs.list.map(a => {
            const datetime = GetDateTimeLabel(new Date(a.created), { format });

            const group = groups[a.group_id];
            const application = applications[a.application_id];
            const function_key = (a.type || '').replace(':', '-');
            const function_label =
                lng_all[
                    `page.reports.app_integrations.label.functions.${function_key}`
                ] || '';
            const result_key = (a.result || '').replace(':', '-');
            const result_label = (a.result || '').toUpperCase();

            const { appkey_id } = a;

            if (!appkeys[appkey_id]) {
                appkeys[appkey_id] = {
                    id: appkey_id,
                    name: a.appkey,
                };
            }

            a_groups[a.group_id] = a_groups[a.group_id]
                ? a_groups[a.group_id] + 1
                : 1;

            a_applications[a.application_id] = a_applications[a.application_id]
                ? a_applications[a.application_id] + 1
                : 1;

            a_appkeys[appkey_id] = a_appkeys[appkey_id]
                ? a_appkeys[appkey_id] + 1
                : 1;

            a_function_types[function_key] = a_function_types[function_key]
                ? {
                      ...a_function_types[function_key],
                      value: a_function_types[function_key].value + 1,
                  }
                : {
                      id: function_key,
                      name: function_label,
                      value: 1,
                  };

            const result_idx = result_key === 'success' ? 'success' : 'error';
            const result_chart_label =
                lng_all[
                    `page.reports.app_integrations.label.results.${result_idx}`
                ] || '';

            a_results[result_idx] = a_results[result_idx]
                ? {
                      ...a_results[result_idx],
                      value: a_results[result_idx].value + 1,
                  }
                : {
                      id: result_idx,
                      name: result_chart_label,
                      value: 1,
                  };

            a_date[datetime.date] = a_date[datetime.date]
                ? a_date[datetime.date] + 1
                : 1;

            a_time[datetime.hour] = a_time[datetime.hour]
                ? a_time[datetime.hour] + 1
                : 1;

            return {
                ...a,
                group: group ? group.name : '---',
                function: function_key || '---',
                function_label,
                result_key: result_key || '---',
                result_label,
                application: application ? application.name : '---',
                created_date: datetime.date,
                created_time: datetime.time,
                created: datetime.string,
                timestamp: datetime.timestamp,
            };
        });

        const appkey_grouped = Object.keys(a_appkeys).map(a => {
            const val = a_appkeys[a];
            const obj = appkeys[a];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const application_grouped = Object.keys(a_applications).map(a => {
            const val = a_applications[a];
            const obj = applications[a];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const group_grouped = Object.keys(a_groups).map(g => {
            const val = a_groups[g];
            const obj = groups[g];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const result_grouped = Object.keys(a_results).map(a => {
            return a_results[a] || {};
        });

        const function_grouped = Object.keys(a_function_types).map(a => {
            return a_function_types[a] || {};
        });

        const period_dates = GetPeriodDates(period);

        const d_labels = [];
        const date_grouped = [];
        period_dates.forEach(p => {
            const formatted_date = new Date(p).toLocaleDateString(
                format.format,
                dateOptions
            );
            const label_date = new Date(p).toLocaleDateString(format.format, {
                month: '2-digit',
                day: '2-digit',
            });
            d_labels.push(label_date);
            date_grouped.push({
                date: label_date,
                value: a_date[formatted_date] || 0,
            });
        });

        const t_labels = [];
        const time_grouped = [];
        for (let t = 0; t < 24; t++) {
            const label_time = t < 10 ? `0${t}h` : `${t}h`;
            t_labels.push(label_time);
            time_grouped.push({
                hour: label_time,
                value: a_time[t] || 0,
            });
        }

        setGroupedData({
            results: result_grouped,
            functions: function_grouped,
            applications: application_grouped,
            appkeys: appkey_grouped,
            groups: group_grouped,
            date: date_grouped,
            time: time_grouped,
            d_labels,
            t_labels,
        });

        setActionLogList(alist);
    }

    async function loadData() {
        setIsLoading(true);
        let query_filters = ``;
        if (options && options.application) {
            query_filters += `&application_id=${options.application}`;
        }
        if (options && options.robot) {
            query_filters += `&robot_id=${options.robot}`;
        }
        if (options && options.appkey) {
            query_filters += `&appkey_id=${options.appkey}`;
        }

        await api
            .get(
                `reports/app_integrations?from=${fromISO}&until=${untilISO}${query_filters}`
            )
            .then(response => {
                const { data } = response;
                filterRawData(data);
                setRawLogData(data.logs);
                setSingleObjFilter(data.appkey || null);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        if (mountedRef.current) {
            loadData();
        }
    }, [settings, fromISO, untilISO, locale]);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    function CSVButton() {
        return (
            <ExportCSVButton
                data={actionLogList}
                headers={headers_file}
                filename={GetFileName(`REPORT-app_integrations`, 'csv')}
            />
        );
    }

    function buildDateChart(title, data, key = 'value') {
        return (
            <ReportDateChart
                title={title}
                color={colors.dashboard_graphs}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function buildHourChart(title, data, key = 'value') {
        return (
            <ReportTimeChart
                title={title}
                color={colors.dashboard_cards}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    const show_group_card = active && active.id === '*';
    const def_class = `col-md-4 col-12 mb-5`;

    const pie_charts = [
        ...(show_group_card
            ? [
                  {
                      key: 'groups',
                      title: 'per_group',
                      color: colors.dashboard_graphs,
                      data: groupedData.groups,
                  },
              ]
            : []),
        {
            key: 'appkey',
            title: 'per_appkey',
            color: colors.dashboard_cards,
            data: groupedData.appkeys,
        },
        {
            key: 'functions',
            title: 'per_function_type',
            color: colors.dashboard_graphs,
            data: groupedData.functions,
        },
        {
            key: 'applications',
            title: 'per_application',
            color: colors.dashboard_cards,
            data: groupedData.applications,
        },
        {
            key: 'results',
            title: 'per_result',
            color: colors.dashboard_graphs,
            data: groupedData.results,
        },
    ];

    return (
        <>
            <ReportHeader
                date_props={{
                    locale,
                }}
                period={{
                    minDate,
                    fromDate: new Date(fromISO),
                    untilDate: new Date(untilISO),
                }}
                handleFromDateChange={handleFromDateChange}
                handleUntilDateChange={handleUntilDateChange}
                reloadData={() => loadData()}
                exportButton={CSVButton()}
                shortcuts={shortcuts}
                handleShortcutClick={_id => handlePeriodShortcut(_id)}
            />
            <div className="sidecard-body mt-3">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        {warnings.length > 0 ? (
                            <div className="col-12 mt-3 mb-3">
                                {warnings.map(w => {
                                    return (
                                        <p key={`label_${w}`}>
                                            <LocaleMessage msg={w} />
                                        </p>
                                    );
                                })}
                            </div>
                        ) : null}
                        {singleObjFilter ? (
                            <>
                                <Divider />
                                <TotalContainer className="mt-3">
                                    <div className="mt-3 mb-3">
                                        <p
                                            className="card-title"
                                            style={{
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            {singleObjFilter.name}
                                        </p>
                                    </div>
                                </TotalContainer>
                            </>
                        ) : null}
                        <Divider />
                        <TotalContainer className="mt-3 mb-5">
                            <CounterCard
                                classes="col-md-4 col-12"
                                title={
                                    <LocaleMessage msg="page.reports.app_integrations.label.total" />
                                }
                                value={rawLogData.total || '0'}
                            />
                        </TotalContainer>
                        <Divider />
                        <InfoContainer className="row mt-3 mb-3">
                            {pie_charts.map(c => {
                                const datakey = 'value';
                                return (
                                    <ReportPieChart
                                        key={`${datakey}_${c.key}`}
                                        classes={def_class}
                                        title={
                                            <LocaleMessage
                                                msg={`page.reports.app_integrations.label.${c.title}`}
                                            />
                                        }
                                        color={c.color}
                                        data={c.data}
                                        dataKey={datakey}
                                        customization={{
                                            stroke: dark_mode
                                                ? '#424242'
                                                : '#ddd',
                                        }}
                                    />
                                );
                            })}
                        </InfoContainer>
                        <Divider />
                        <div className="row mt-3 mb-3">
                            {buildDateChart(
                                <LocaleMessage msg="page.reports.app_integrations.label.per_date" />,
                                groupedData.date
                            )}
                            {buildHourChart(
                                <LocaleMessage msg="page.reports.app_integrations.label.per_hour" />,
                                groupedData.time
                            )}
                        </div>
                        <Divider />
                        <InfoContainer className="row mt-3 mb-3">
                            <TableArea className="col-12 mb-3 table-responsive-material">
                                <div className="mt-3 mb-3">
                                    <p className="card-title">
                                        <LocaleMessage msg="page.reports.app_integrations.label.history" />
                                    </p>
                                </div>
                                <DataTable
                                    maxHeight="100%"
                                    headerColumns={headers_table}
                                    data={actionLogList || []}
                                    orderDirection="desc"
                                    orderColumn="timestamp"
                                    hasActions={false}
                                    handleTableRowClick={() => {}}
                                    defaultRowsPerPage={25}
                                    sortable
                                />
                            </TableArea>
                        </InfoContainer>
                    </>
                )}
            </div>
        </>
    );
}

AppIntegrationsReports.defaultProps = {
    language: '',
};

AppIntegrationsReports.propTypes = {
    settings: PropTypes.object.isRequired,
    headerSettings: PropTypes.object.isRequired,
    requestError: PropTypes.func.isRequired,
    counterData: PropTypes.object.isRequired,
    language: PropTypes.string,
};
