/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdForum,
    MdBrightness7,
    MdDirectionsRun,
    MdAccessTime,
    MdPermContactCalendar,
    MdVideoCall,
    MdCompareArrows,
    MdAssignment,
    MdDescription,
    MdEmojiEmotions,
    MdVpnKey,
} from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { subMonths, subDays, formatISO, differenceInDays } from 'date-fns';
import PropTypes from 'prop-types';
import * as qs from 'query-string';
import { useQueryState } from 'use-location-state';

import { useTheme } from '@material-ui/core/styles';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import Page404 from '~/pages/Open/Page404';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import ActionReports from './Actions';
import ConversationDanceRunReports from './Actions/DanceRuns';
import MapNavigationReports from './Actions/MapNavigations';
import ConversationScriptRunReports from './Actions/ScriptRuns';
import SurveySessionReports from './Actions/SurveySessions';
import AppIntegrationReports from './AppIntegrations';
import AutonomousActionReports from './AutonomousActions';
import DeliveriyReports from './Deliveries';
import DisinfectionReports from './Disinfections';
import InviteReports from './Invites';
import ConversationReports from './Messages';
import OperationReports from './Operations';
import TelepresenceReports from './TelepresenceCalls';

const action_breadcrumbs = [
    {
        title: <LocaleMessage msg="breadcrumbs.actions" />,
        url: '/reports/actions',
    },
];

const reports = {
    operations: {
        title: <LocaleMessage msg="page.reports.operations.title" />,
        icon: MdAccessTime,
        content: OperationReports,
    },
    deliveries: {
        title: <LocaleMessage msg="page.reports.deliveries.title" />,
        icon: MdCompareArrows,
        content: DeliveriyReports,
    },
    disinfections: {
        title: <LocaleMessage msg="page.reports.disinfections.title" />,
        icon: MdBrightness7,
        content: DisinfectionReports,
    },
    invites: {
        title: <LocaleMessage msg="page.reports.invites.title" />,
        icon: MdPermContactCalendar,
        content: InviteReports,
    },
    telepresence: {
        title: <LocaleMessage msg="page.reports.telepresence.title" />,
        icon: MdVideoCall,
        content: TelepresenceReports,
    },
    messages: {
        title: <LocaleMessage msg="page.reports.messages.title" />,
        icon: MdForum,
        content: ConversationReports,
    },
    actions: {
        title: <LocaleMessage msg="page.reports.actions.title" />,
        icon: MdDirectionsRun,
        content: ActionReports,
    },
    autonomous_actions: {
        title: <LocaleMessage msg="page.reports.autonomous_actions.title" />,
        icon: MdDirectionsRun,
        content: AutonomousActionReports,
    },
    navigation_triggers: {
        title: <LocaleMessage msg="page.reports.actions.map_nav.title" />,
        icon: MdAssignment,
        content: MapNavigationReports,
        breadcrumbs: action_breadcrumbs,
    },
    survey_triggers: {
        title: (
            <LocaleMessage msg="page.reports.actions.survey_sessions.title" />
        ),
        icon: MdAssignment,
        content: SurveySessionReports,
        breadcrumbs: action_breadcrumbs,
    },
    script_triggers: {
        title: <LocaleMessage msg="page.reports.actions.script_runs.title" />,
        icon: MdDescription,
        content: ConversationScriptRunReports,
        breadcrumbs: action_breadcrumbs,
    },
    dance_triggers: {
        title: <LocaleMessage msg="page.reports.actions.dance_runs.title" />,
        icon: MdEmojiEmotions,
        content: ConversationDanceRunReports,
        breadcrumbs: action_breadcrumbs,
    },
    app_integrations: {
        title: <LocaleMessage msg="page.reports.app_integrations.title" />,
        icon: MdVpnKey,
        content: AppIntegrationReports,
    },
};
const RADIAN = Math.PI / 180;

export default function Reports({ match, location }) {
    const dispatch = useDispatch();

    const { type } = match.params;
    const { search, hash } = location;
    const query = qs.parse(search || hash);

    const settings = useSelector(state => state.settings || null);
    const { active, dark_mode } = settings;

    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';

    const { profile } = useSelector(state => state.user || {});
    const { permission } = profile;

    const colors = GetPluginspaceTheme(useTheme());

    const date_format = settings.locale;
    const date_loc = getDateLocale(settings);
    const date_opt = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    const time_opt = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23',
    };

    const time_limit = 3;
    const mountedRef = React.useRef(true);
    const minDate = ['master', 'super_admin'].includes(permission)
        ? null
        : new Date(subMonths(new Date(), time_limit));

    const now = new Date();
    const def_from = new Date(subDays(now, 30));

    const [queryUntil, setQueryUntil] = useQueryState('until', '');
    const [queryFrom, setQueryFrom] = useQueryState('from', '');

    const [untilDate, setUntilDate] = useState(
        queryUntil ? new Date(queryUntil) : now
    );
    const [fromDate, setFromDate] = useState(
        queryFrom ? new Date(queryFrom) : def_from
    );

    const [counterData, setCounterData] = useState({});

    const shortcut_list = {
        today: <LocaleMessage msg="button.today" />,
        '30_days': <LocaleMessage msg="button.last_30_days" />,
        '60_days': <LocaleMessage msg="button.last_60_days" />,
        '90_days': <LocaleMessage msg="button.last_90_days" />,
        custom: <LocaleMessage msg="button.custom" />,
    };

    function getDefaultShortcut() {
        if (differenceInDays(untilDate, new Date()) !== 0) return 'custom';
        const diff = differenceInDays(untilDate, fromDate);
        if (diff === 0) return 'today';
        if (diff === 30 || diff === 60 || diff === 90) return `${diff}_days`;

        return 'custom';
    }

    const [isLoading, setIsLoading] = useState(true);
    const [currShortcut, setCurrShortcut] = useState(getDefaultShortcut());

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
    }

    function getFilterInfo(values) {
        const infos = {};
        if (values) {
            values.forEach(m => {
                infos[m.id] = m;
            });
        }
        return infos;
    }

    async function loadData() {
        if (type) {
            setIsLoading(true);
            await api
                .get(`reports/counter?type=${type}`)
                .then(response => {
                    const { data } = response;
                    const filters = {};
                    Object.keys(data).forEach(f => {
                        filters[f] = getFilterInfo(data[f]);
                    });

                    setCounterData(filters);
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [active, type]);

    useEffect(() => {
        setCurrShortcut(getDefaultShortcut());
    }, [fromDate, untilDate]);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const handleFromDateChange = date => {
        setFromDate(date);
        setQueryFrom(formatISO(new Date(date)));
    };

    const handleUntilDateChange = date => {
        setUntilDate(date);
        setQueryUntil(formatISO(new Date(date)));
    };

    function handlePeriodShortcut(shortcut) {
        const newUntil = new Date();
        switch (shortcut) {
            case 'today': {
                setFromDate(newUntil);
                setUntilDate(newUntil);
                setQueryFrom(formatISO(newUntil));
                setQueryUntil(formatISO(newUntil));
                break;
            }
            case '30_days': {
                const newFrom = new Date(subDays(newUntil, 30));
                setFromDate(newFrom);
                setUntilDate(newUntil);
                setQueryFrom(formatISO(newFrom));
                setQueryUntil(formatISO(newUntil));
                break;
            }
            case '60_days': {
                const newFrom = new Date(subDays(newUntil, 60));
                setFromDate(newFrom);
                setUntilDate(newUntil);
                setQueryFrom(formatISO(newFrom));
                setQueryUntil(formatISO(newUntil));
                break;
            }
            case '90_days': {
                const newFrom = new Date(subDays(newUntil, 90));
                setFromDate(newFrom);
                setUntilDate(newUntil);
                setQueryFrom(formatISO(newFrom));
                setQueryUntil(formatISO(newUntil));
                break;
            }
            default:
                break;
        }
    }

    const CustomTimeTooltip = data => {
        const { payload } = data;
        const payload_content =
            payload[0] && payload[0].payload ? payload[0].payload : {};
        const payload_name = payload_content.name ? payload_content.name : '';
        const payload_value = payload_content.time_label
            ? payload_content.time_label
            : '';

        if (active && payload) {
            return (
                <div
                    style={{
                        backgroundColor: '#fff',
                        padding: '10px',
                        border: '1px solid #ddd',
                        color: '#000',
                    }}
                >
                    <span>{`${payload_name}: ${payload_value}`}</span>
                </div>
            );
        }

        return null;
    };

    const CustomPieLabel = data => {
        const { cx, cy, midAngle, fill, outerRadius, name } = data;
        const radius = outerRadius + 20;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        const ellipsed = name.length > 10 ? `${name.substring(0, 9)}...` : name;

        return (
            <text
                x={x}
                y={y}
                fill={fill}
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
            >
                {ellipsed}
            </text>
        );
    };

    const reportSettings = reports[type] || null;
    const ReportContent =
        reportSettings && reportSettings.content
            ? reportSettings.content
            : null;

    return reportSettings ? (
        <PageContent
            title={reportSettings.title}
            currentPage={reportSettings.title}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                ...(reportSettings.breadcrumbs
                    ? reportSettings.breadcrumbs
                    : []),
            ]}
            loading={isLoading}
        >
            <div className="col-12 mb-5 mt-3">
                <CardSideBordered
                    title={reportSettings.title}
                    Icon={reportSettings.icon}
                    hide
                >
                    {ReportContent ? (
                        <ReportContent
                            counterData={counterData}
                            settings={{
                                dark_mode,
                                colors,
                                active,
                                format: date_format,
                                locale: date_loc,
                                dateOptions: date_opt,
                                timeOptions: time_opt,
                            }}
                            headerSettings={{
                                fromISO: formatISO(new Date(fromDate)),
                                untilISO: formatISO(new Date(untilDate)),
                                from: fromDate,
                                until: untilDate,
                                minDate,
                                shortcuts: {
                                    active: currShortcut,
                                    list: shortcut_list,
                                },
                                handleFromDateChange: date =>
                                    handleFromDateChange(date),
                                handleUntilDateChange: date =>
                                    handleUntilDateChange(date),
                                handlePeriodShortcut: shortcut =>
                                    handlePeriodShortcut(shortcut),
                            }}
                            language={lang}
                            options={query || {}}
                            requestError={error => requestError(error)}
                            CustomPieLabel={CustomPieLabel}
                            CustomTimeTooltip={CustomTimeTooltip}
                        />
                    ) : null}
                </CardSideBordered>
            </div>
        </PageContent>
    ) : (
        <Page404 />
    );
}

Reports.defaultProps = {
    location: {},
};

Reports.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object,
};
