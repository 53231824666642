/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';

import { differenceInSeconds } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';

import CustomScrollbars from '~/components/CustomScrollBar';
import LocaleMessage from '~/components/LocaleMessage';

import api from '~/services/pluginbot-api';
import GetOperationTime from '~/util/GetOperationTime';

import { DarkDialog } from '../../../styles';
import {
    Card,
    MissionDialog,
    FullwidthDialogActions,
    IconButton,
} from './styles';

const h100 = { height: '100%' };

const rtype_missions = {
    cruzr: ['start_location', 'map_navigation'],
    temi: ['start_location', 'map_navigation'],
};

export default function MissionCard({
    options,
    width,
    permission,
    user,
    now,
    robot,
    robotType,
    state,
    application,
    sendAction,
    renderLoading,
    requestError,
}) {
    const status = state.status || {};
    const { operation } = status;
    const op_action = operation && operation.action ? operation.action : null;
    const op_user = operation && operation.user ? operation.user : null;
    const op_since =
        op_action && op_action.since ? new Date(op_action.since) : null;
    const op_time_s = op_since
        ? differenceInSeconds(now.datetime, op_since)
        : null;
    const op_time =
        op_time_s && op_time_s > 0 ? GetOperationTime(op_time_s) : null;
    const op_type = op_action && op_action.type ? op_action.type : '';
    const op_data = op_action && op_action.settings ? op_action.settings : {};

    const show_operation = op_action && op_action.type !== 'waiting';

    const is_op_user = op_user && user && op_user.id === user.id;
    const allow_permission = permission !== 'viewer';
    const window_size = 'md';

    const [isLoading, setIsLoading] = useState(true);
    const [cancelMission, setCancelMission] = useState(false);
    const [notifyCancel, setNotifyCancel] = useState(false);

    const [, setAppSettings] = useState(null);
    const [mapPoints, setMapPoints] = useState([]);
    const [currMission, setCurrMission] = useState({});

    const mission_type =
        currMission && currMission.type ? currMission.type : '';
    const mission_data =
        currMission && currMission.data ? currMission.data : {};

    async function loadMapPoints(map_id) {
        await api
            .get(`map_points?map_layer=${map_id}`)
            .then(async response => {
                const { data } = response;
                setMapPoints(data);
            })
            .catch(() => {});
    }

    async function loadMap(r_map) {
        const layer_id = r_map.map_layer_id;
        if (r_map.active && layer_id) {
            loadMapPoints(layer_id);
        }
        setIsLoading(false);
    }

    async function loadAppSettings(_id) {
        await api
            .get(`applications/${_id}`)
            .then(async response => {
                const { data } = response;
                const settings = data && data.settings ? data.settings : {};
                const map_settings = settings.map_navigation || {};
                const r_map = map_settings[robotType] || {};
                loadMap(r_map);
                setAppSettings(data);
            })
            .catch(() => {});
        setIsLoading(false);
    }

    async function saveUserMission(data) {
        const body = {
            application_id: application.id,
            robot_id: robot.id,
            action: data,
        };

        await api
            .post(`/missions/rc3`, body)
            .then(() => {
                sendAction({
                    action: 'run_mission',
                });
            })
            .catch(e => {
                requestError(e);
            });
    }

    function onExitClick() {
        if (is_op_user) {
            const exit_params = options.exit_mode || {};
            sendAction(exit_params);
        }
        setCurrMission(null);
        setIsLoading(true);
    }

    const handleTabClosing = () => {
        onExitClick();
    };

    useEffect(() => {
        window.addEventListener('unload', handleTabClosing);
        return () => {
            window.removeEventListener('unload', handleTabClosing);
        };
    }, []);

    useEffect(() => {
        if (application && application.id) {
            loadAppSettings(application.id);
        }
    }, [application.id, is_op_user]);

    function sendCancelMission() {
        setIsLoading(true);
        sendAction({
            action: 'cancel_mission',
            data: {
                notify: notifyCancel,
            },
        });
        setCancelMission(false);
    }

    function sendMission(mission) {
        setIsLoading(true);
        saveUserMission(mission);
        setCurrMission(null);
    }

    function clearMission() {
        setCurrMission({});
    }

    function renderDialog(title, content, actions, onClose, dialog_width) {
        return (
            <Dialog
                open
                onClose={() => onClose()}
                fullWidth
                maxWidth={dialog_width || 'sm'}
            >
                <DarkDialog style={{ flexDirection: 'column' }}>
                    {title ? (
                        <DialogTitle>
                            <span style={{ textTransform: 'uppercase' }}>
                                {title}
                            </span>
                        </DialogTitle>
                    ) : null}
                    {content}
                    {actions ? (
                        <FullwidthDialogActions className="mt-3 mb-1 px-3">
                            {actions}
                        </FullwidthDialogActions>
                    ) : null}
                </DarkDialog>
            </Dialog>
        );
    }

    // LOCALIZATION MISSION

    function prepareStartLocation() {
        const action_data = {
            type: 'start_location',
            data: {},
        };
        setCurrMission(action_data);
    }

    function renderLocalizationConfirmation() {
        return (
            <>
                <span
                    className="mb-5 MuiTypography-h6"
                    style={{ textTransform: 'uppercase' }}
                >
                    <LocaleMessage msg="rc3.robot.data.card.mission.confirm.start_location" />
                </span>
            </>
        );
    }

    // NAVIGATION MISSION

    // function prepareGoToBase() {
    //     const action_data = {
    //         type: 'go_to_charger',
    //         data: {
    //             map: {
    //                 // id: point.map_layer ? point.map_layer.id : '',
    //                 // name: point.map_layer ? point.map_layer.reference : '',
    //                 // reference: point.map_layer ? point.map_layer.reference : '',
    //             },
    //         },
    //     };
    //     setCurrMission(action_data);
    // }

    function prepareNavigationMission(point) {
        const action_data = {
            type: 'map_navigation',
            data: {
                map: {
                    id: point.map_layer ? point.map_layer.id : '',
                    name: point.map_layer ? point.map_layer.reference : '',
                    reference: point.map_layer ? point.map_layer.reference : '',
                },
                point: {
                    id: point.id,
                    name: point.name,
                    reference: point.reference,
                    location: point.reference,
                },
            },
        };
        setCurrMission(action_data);
    }

    function renderPointShortcut(point, size) {
        return (
            <div className={`col-md-${size} col-6 mb-3`} key={point.id}>
                <Button
                    fullWidth
                    size="medium"
                    color="primary"
                    variant="contained"
                    onClick={() => prepareNavigationMission(point)}
                >
                    {point.name}
                </Button>
            </div>
        );
    }

    function renderNavigationMission() {
        const show_map = false;

        return (
            <>
                <div className="mission-main mx-0 px-0">
                    <div
                        className={
                            show_map
                                ? 'col-md-3 col-12 mission-side p-3'
                                : 'col-md-9 col-12 mission-main-content p-3'
                        }
                    >
                        <div className="mb-5 col-12 text-center row">
                            <IconButton className="col-1">
                                <MdClose
                                    size={22}
                                    onClick={() => clearMission()}
                                />
                            </IconButton>
                            <div className="col-10">
                                <LocaleMessage msg="rc3.robot.data.card.mission.points" />
                            </div>
                            <IconButton className="col-1" />
                        </div>
                        <CustomScrollbars className="scrollbar">
                            <div className="row pb-5 text-center">
                                {/* <div className="col-md-12 col-6 mb-3">
                                    <Button
                                        fullWidth
                                        size="medium"
                                        color="primary"
                                        variant="contained"
                                        onClick={() => prepareGoToBase()}
                                    >
                                        GO TO CHARGING BASE
                                    </Button>
                                </div> */}
                                {mapPoints.map(p =>
                                    renderPointShortcut(
                                        p,
                                        show_map ? '12' : '4'
                                    )
                                )}
                            </div>
                        </CustomScrollbars>
                    </div>
                    {show_map ? (
                        <div className="col-md-9">
                            <CustomScrollbars className="scrollbar" />
                        </div>
                    ) : null}
                </div>
            </>
        );
    }

    function renderNavigationConfirmation() {
        const action_data =
            currMission && currMission.data ? currMission.data : {};
        const on_started_txt =
            action_data.on_started && action_data.on_started.text
                ? action_data.on_started.text
                : '';
        const on_finished_txt =
            action_data.on_finished && action_data.on_finished.text
                ? action_data.on_finished.text
                : '';

        return (
            <>
                <span
                    className="mb-5 MuiTypography-h6"
                    style={{ textTransform: 'uppercase' }}
                >
                    <LocaleMessage msg="rc3.robot.data.card.mission.confirm.map_navigation" />{' '}
                    {mission_data && mission_data.point
                        ? mission_data.point.name.toUpperCase()
                        : '---'}
                </span>
                <div className="col-12 mb-3">
                    <TextField
                        focused
                        fullWidth
                        variant="outlined"
                        label={
                            <LocaleMessage msg="rc3.robot.data.card.mission.confirm.map_navigation.on_started" />
                        }
                        value={on_started_txt}
                        onChange={event =>
                            setCurrMission({
                                ...currMission,
                                data: {
                                    ...action_data,
                                    on_started: {
                                        action: 'speak_message',
                                        text: event.target.value,
                                    },
                                },
                            })
                        }
                        inputProps={{
                            style: { color: 'white' },
                        }}
                    />
                </div>
                <div className="col-12 mb-3">
                    <TextField
                        focused
                        fullWidth
                        variant="outlined"
                        label={
                            <LocaleMessage msg="rc3.robot.data.card.mission.confirm.map_navigation.on_finished" />
                        }
                        value={on_finished_txt}
                        onChange={event =>
                            setCurrMission({
                                ...currMission,
                                data: {
                                    ...action_data,
                                    on_finished: {
                                        action: 'speak_message',
                                        text: event.target.value,
                                    },
                                },
                            })
                        }
                        inputProps={{
                            style: { color: 'white' },
                        }}
                    />
                </div>
            </>
        );
    }

    // MISSION CANCEL PARAMETERS

    function renderNotifyCancel() {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        value={notifyCancel}
                        checked={notifyCancel || false}
                        onChange={event =>
                            setNotifyCancel(event.target.checked)
                        }
                    />
                }
                label={
                    <LocaleMessage msg="rc3.robot.data.card.mission.cancel.notify" />
                }
            />
        );
    }

    const missions = {
        start_location: {
            label: 'rc3.robot.data.card.mission.type.start_localization',
            onClick: () => prepareStartLocation(),
            content: null,
            confirmation: renderLocalizationConfirmation(),
            cancel: <span />,
        },
        map_navigation: {
            label: 'rc3.robot.data.card.mission.type.map_navigation',
            onClick: () =>
                setCurrMission({
                    type: 'map_navigation',
                }),
            content: renderNavigationMission(),
            confirmation: renderNavigationConfirmation(),
            cancel: (
                <>
                    <span
                        className="mb-3 MuiTypography-h6"
                        style={{ textTransform: 'uppercase' }}
                    >
                        <LocaleMessage msg="rc3.robot.data.card.mission.running.going_to" />{' '}
                        {op_data.point
                            ? op_data.point.name.toUpperCase()
                            : '---'}
                    </span>
                    {renderNotifyCancel()}
                </>
            ),
        },
    };

    function renderMissionSelection() {
        const available_missions = rtype_missions[robotType] || [];

        return (
            <>
                <div className="mission-main mx-0 px-0">
                    <div className="col-md-10 col-12 mission-main-content p-3">
                        <div className="mb-5 col-12 text-center">
                            <LocaleMessage msg="rc3.robot.data.card.mission.select" />
                        </div>
                        <CustomScrollbars className="scrollbar">
                            <div
                                className="row pb-5 text-center"
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                }}
                            >
                                {available_missions.map(m => {
                                    const mission_parameters =
                                        missions[m] || {};
                                    return (
                                        <div
                                            className="col-md-5 col-6 mb-3"
                                            key={`mission_btn_${m}`}
                                        >
                                            <Button
                                                fullWidth
                                                size="large"
                                                color="primary"
                                                variant="contained"
                                                onClick={() =>
                                                    mission_parameters.onClick()
                                                }
                                            >
                                                <LocaleMessage
                                                    msg={
                                                        mission_parameters.label
                                                    }
                                                />
                                            </Button>
                                        </div>
                                    );
                                })}
                            </div>
                        </CustomScrollbars>
                    </div>
                </div>
            </>
        );
    }

    function renderCreateMission() {
        const mission_creation = missions[mission_type]
            ? missions[mission_type].content
            : renderMissionSelection();

        return renderDialog(
            null,
            <>
                <MissionDialog className="mission-box">
                    <div className="mission-box-main">
                        <div className="mission-main-header">
                            <div className="mission-main-header-info">
                                <div
                                    className="chat-contact-name"
                                    style={{ textTransform: 'uppercase' }}
                                >
                                    <LocaleMessage msg="rc3.robot.data.card.mission.title" />
                                </div>
                                {!isLoading ? (
                                    <Button
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        onClick={() => onExitClick()}
                                    >
                                        <LocaleMessage msg="rc3.robot.data.card.mission.exit" />
                                    </Button>
                                ) : null}
                            </div>
                        </div>
                        {isLoading ? (
                            <div className="mission-load m-3">
                                {renderLoading()}
                            </div>
                        ) : (
                            mission_creation
                        )}
                    </div>
                </MissionDialog>
            </>,
            null,
            () => onExitClick(),
            window_size
        );
    }

    function renderConfirmMission() {
        const mission_confirmation = missions[mission_type]
            ? missions[mission_type].confirmation
            : null;

        return renderDialog(
            <></>,
            <>
                <DialogContent className="col-12">
                    <div
                        className="col-12"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <span
                            className="mb-5"
                            style={{ textTransform: 'uppercase' }}
                        >
                            <LocaleMessage msg="rc3.robot.data.card.mission.confirm" />
                        </span>
                        {mission_confirmation}
                    </div>
                </DialogContent>
            </>,
            <div
                className="col-12 row p-0 mx-0 mb-3"
                style={{ justifyContent: 'space-between' }}
            >
                <div className="col-md-4 col-6">
                    <Button
                        fullWidth
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={() => setCurrMission(null)}
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                </div>
                <div className="col-md-4 col-6">
                    <Button
                        autoFocus
                        fullWidth
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={() => sendMission(currMission)}
                    >
                        <LocaleMessage msg="button.send" />
                    </Button>
                </div>
            </div>,
            () => onExitClick(),
            'md'
        );
    }

    function renderCancelMission() {
        const mission_cancel = missions[op_type]
            ? missions[op_type].cancel
            : null;

        const close_dialog = () => setCancelMission(false);
        return renderDialog(
            <LocaleMessage msg="rc3.robot.data.card.mission.cancel" />,
            <>
                <DialogContent>
                    <div
                        className="col-12"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {mission_cancel}
                    </div>
                </DialogContent>
            </>,
            <div
                className="col-12 row p-0 mx-0 mb-3"
                style={{ justifyContent: 'space-between' }}
            >
                <div className="col-md-4 col-6">
                    <Button
                        fullWidth
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={close_dialog}
                    >
                        <LocaleMessage msg="button.close" />
                    </Button>
                </div>
                <div className="col-md-4 col-6">
                    <Button
                        autoFocus
                        fullWidth
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={() => sendCancelMission()}
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                </div>
            </div>,
            close_dialog,
            'sm'
        );
    }

    function renderMissionOperation() {
        return currMission && currMission.data
            ? renderConfirmMission()
            : renderCreateMission();
    }

    function renderMissionContent() {
        switch (op_type) {
            case 'map_navigation': {
                return (
                    <span className="value">
                        <LocaleMessage msg="rc3.robot.data.card.mission.running.going_to" />{' '}
                        {op_data.point ? op_data.point.name : '---'}
                    </span>
                );
            }
            default:
                return null;
        }
    }

    function renderInUseCard() {
        return (
            <div className={width} style={h100}>
                {cancelMission ? renderCancelMission() : null}
                <Box style={h100}>
                    <Card>
                        <div className="content">
                            <span className="jr-fs-md title mb-3">
                                {show_operation ? (
                                    <LocaleMessage msg="rc3.robot.data.card.mission.running" />
                                ) : (
                                    <LocaleMessage msg="rc3.robot.data.card.mission.waiting" />
                                )}
                            </span>
                            <div className="info mb-3">
                                <span className="mid-value">
                                    {op_time ? op_time.label : '--:--:--'}
                                </span>
                            </div>
                            <div className="info text-center mb-3">
                                <span className="value">
                                    <LocaleMessage msg="list.operations.labels.by" />{' '}
                                    {op_user ? op_user.name : '---'}
                                </span>
                            </div>
                            {show_operation ? (
                                <div className="info text-center mb-3">
                                    {renderMissionContent()}
                                </div>
                            ) : null}
                        </div>
                        {show_operation ? (
                            <Button
                                fullWidth
                                className="mb-3"
                                variant="contained"
                                color="primary"
                                disabled={!allow_permission}
                                onClick={() => {
                                    setCancelMission(true);
                                    setNotifyCancel(false);
                                }}
                            >
                                <LocaleMessage msg="rc3.robot.data.card.mission.cancel" />
                            </Button>
                        ) : null}
                    </Card>
                </Box>
            </div>
        );
    }

    function renderContent() {
        return show_operation || !is_op_user
            ? renderInUseCard()
            : renderMissionOperation();
    }

    return operation && operation.mode === 'mission' ? renderContent() : null;
}

MissionCard.propTypes = {
    now: PropTypes.object,
    width: PropTypes.string,
    permission: PropTypes.string,
    options: PropTypes.object,
    user: PropTypes.object,
    robot: PropTypes.object,
    robotType: PropTypes.string,
    state: PropTypes.object,
    application: PropTypes.object,
    sendAction: PropTypes.func.isRequired,
    renderLoading: PropTypes.func.isRequired,
    requestError: PropTypes.func.isRequired,
};

MissionCard.defaultProps = {
    width: 'col-12',
    permission: '',
    user: {},
    robot: {},
    robotType: '',
    state: {},
    options: {},
    application: {},
    now: {},
};
