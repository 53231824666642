/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import { Divider } from '@material-ui/core';

import FormSelect from '~/components/Form/Select';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';

const center = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export default function TelepresenceAppIntegration({
    notifyChange,
    telepresenceApp,
    setTelepresenceApp,
    apps,
}) {
    const tp_apps = apps.filter(a => {
        return a.type_name === 'telepresence';
    });

    const {
        active: telepresence_active,
        app_id: telepresence_app,
    } = telepresenceApp;

    function handleEditSettings(key, value) {
        if (value !== telepresenceApp[key]) {
            notifyChange();
        }
        setTelepresenceApp({
            ...telepresenceApp,
            [key]: value,
        });
    }

    return (
        <>
            <Divider />
            <div className="sidecard-header mb-3">
                <h2>
                    <LocaleMessage msg="page.applications.conversation.hive_apps.telepresence.title" />
                </h2>
            </div>
            <div
                className={`row ${
                    !telepresence_active ? 'col-md-8' : ''
                } col-12 mb-5`}
                style={center}
            >
                <div
                    className={`${
                        telepresence_active ? 'col-md-4' : 'col-md-6'
                    } col-12`}
                >
                    <FormSwitch
                        value={telepresence_active || false}
                        onChange={event => {
                            handleEditSettings('active', event.target.checked);
                        }}
                        label={
                            <LocaleMessage msg="page.applications.conversation.hive_apps.telepresence.use" />
                        }
                    />
                </div>
                {telepresence_active ? (
                    <div className="col-md-4">
                        <FormSelect
                            options={tp_apps || []}
                            labelKey="name"
                            label={
                                <LocaleMessage msg="page.applications.conversation.hive_apps.bind" />
                            }
                            onChange={value => {
                                handleEditSettings('app_id', value);
                            }}
                            value={telepresence_app || ''}
                        />
                    </div>
                ) : null}
            </div>
            <Divider />
        </>
    );
}

TelepresenceAppIntegration.propTypes = {
    notifyChange: PropTypes.func.isRequired,
    apps: PropTypes.array.isRequired,
    telepresenceApp: PropTypes.object.isRequired,
    setTelepresenceApp: PropTypes.func.isRequired,
};
