export default function generateTheme(theme, dark = false) {
    return {
        dark_mode: dark,
        palette: {
            ...(dark && {
                type: 'dark',
            }),
            pluginspace: theme,
            white: {
                main: '#ffffff',
                dark: '#fff',
                light: '#fff',
                contrastText: '#333',
            },
            primary: {
                main: theme.primary,
                contrastText: '#fff',
            },
            secondary: {
                main: theme.secondary,
                contrastText: '#fff',
            },
        },
        typography: {
            button: {
                fontWeight: 400,
                textAlign: 'capitalize',
            },
        },
    };
}
