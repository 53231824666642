/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';
import UnavailablePlugin from '~/components/UnavailablePlugin';

import api from '~/services/pluginbot-api';
import getDateLocale from '~/util/GetDateLocale';

// import ContactIdentifier from './ContactIdentifier';
import TelepresenceAppIntegration from './TelepresenceAppIntegration';

const useStyles = makeStyles(() => {
    return {
        row: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
});

export default function HIVEApps({
    setToast,
    handleError,
    appSettings,
    updateObject,
}) {
    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);

    const { settings, group } = appSettings;
    const group_id = group ? group.id : '*';

    // const { lang } = date_loc;
    // const app_languages =
    //     settings && settings.language ? settings.language : {};
    // const language_list = app_languages.list || ['pt_BR'];

    // const { dialog_id } = application_content;

    const [hiveApps, setHiveApps] = useState(
        settings && settings.hive_apps ? settings.hive_apps : {}
    );

    const [allowPlugin, setAllowPlugin] = useState(true);

    const [isLoading, setIsLoading] = useState(true);
    const [needSaving, setNeedSaving] = useState(false);

    const [apps, setApps] = useState([]);
    // const [innerObj, setInnerObj] = useState('new');
    // const [subjectList, setSubjectList] = useState([]);

    const [openSettings] = useState(null);

    const classes = useStyles();
    const { telepresence } = hiveApps;

    // const identifier_settings = {
    //     dialog_id,
    //     application_id: appSettings.id,
    //     subjects: subjectList,
    // };

    function requestError(error) {
        handleError(error);
        setIsLoading(false);
    }

    async function loadApps() {
        setIsLoading(true);
        await api
            .get(`applications`)
            .then(async response => {
                const filtered = response.data.filter(m => {
                    return m.group && m.group.id === group_id;
                });
                const data = filtered.map(a => ({
                    id: a.id,
                    name: a.name,
                    type_name: a.app_type ? a.app_type.slug : '',
                }));
                setApps(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadPlugin() {
        await api
            .get(`pluginspaces/addons/hive_apps`)
            .then(async response => {
                const { data } = response;
                if (!data.id) {
                    setAllowPlugin(false);
                }
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    // async function loadConversationContent() {
    //     await api
    //         .get(`dialogs/${dialog_id}/content?intents=true&subjects=true`)
    //         .then(response => {
    //             const { data } = response;
    //             const { subjects } = data;

    //             const values = subjects.map(s => {
    //                 return s.value;
    //             });

    //             setSubjectList(values);
    //             setIsLoading(false);
    //         })
    //         .catch(error => requestError(error));
    // }

    // useEffect(() => {
    //     if (dialog_id) {
    //         loadConversationContent();
    //     }
    // }, [dialog_id]);

    // function closeInsidePage() {
    //     setOpenSettings(null);
    // }

    useEffect(() => {
        loadPlugin();
    }, []);

    useEffect(() => {
        loadApps();
    }, [date_loc]);

    async function handleSubmit() {
        const data = { hive_apps: hiveApps };
        updateObject(data);
    }

    function renderSubmitButton() {
        return (
            <div className={`row mt-5 mb-5 ${classes.row}`}>
                <div className="col-md-8 col-12 mt-2">
                    <Button
                        className="p-3"
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                        fullWidth
                        size="large"
                    >
                        <LocaleMessage msg="button.save" />
                    </Button>
                </div>
            </div>
        );
    }

    function renderAppIntegrationSettings() {
        return (
            <div className="col-12">
                <TelepresenceAppIntegration
                    setToast={t => setToast(t)}
                    handleError={e => requestError(e)}
                    notifyChange={() => setNeedSaving(true)}
                    hasChange={needSaving}
                    apps={apps || []}
                    appSettings={appSettings}
                    telepresenceApp={telepresence || {}}
                    setTelepresenceApp={a =>
                        setHiveApps({
                            ...hiveApps,
                            telepresence: a,
                        })
                    }
                />
                {renderSubmitButton()}
            </div>
        );
    }

    function renderInsidePage() {
        switch (openSettings) {
            // case 'contact_identifier':
            //     return (
            //         <div className="col-12">
            //             <ContactIdentifier
            //                 languageList={language_list}
            //                 language={lang}
            //                 settings={{
            //                     ...identifier_settings,
            //                     id: innerObj,
            //                     telepresence: telepresence || {},
            //                 }}
            //                 setToast={t => setToast(t)}
            //                 handleError={e => requestError(e)}
            //                 closePage={() => closeInsidePage()}
            //                 subjects={subjectList}
            //             />
            //         </div>
            //     );
            default:
                return null;
        }
    }

    return (
        <div className="col-12 mb-3 row">
            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg="page.applications.conversation.hive_apps.title" />
                </h2>
            </div>
            <div
                className="col-12 mb-3"
                style={{
                    padding: '0px 25px',
                }}
            >
                <br />
                <LocaleMessage msg="page.applications.conversation.hive_apps.label.tutorial_apps" />
                <br />
                <LocaleMessage msg="page.applications.conversation.hive_apps.label.caution_apps" />
            </div>
            <div className="sidecard-body">
                {isLoading ? (
                    <Splash />
                ) : (
                    <div
                        className="col-12 row"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {!allowPlugin ? (
                            <UnavailablePlugin />
                        ) : (
                            <>
                                {openSettings
                                    ? renderInsidePage()
                                    : renderAppIntegrationSettings()}
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

HIVEApps.propTypes = {
    updateObject: PropTypes.func.isRequired,
    setToast: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    appSettings: PropTypes.object.isRequired,
};
