export default function compareVersions(min_version, version) {
    const version_parts = version.split('.');

    if (Number(version_parts[0]) > min_version[0]) return true;

    if (Number(version_parts[0]) === min_version[0]) {
        if (Number(version_parts[1]) > min_version[1]) return true;
        if (Number(version_parts[1]) === min_version[1]) {
            return Number(version_parts[2]) >= min_version[2];
        }
        return false;
    }
    return false;
}
